<template>
  <div class="css_home">
    <div class="css_cont">
      <div class="css_labelcont">
        <p class="css_tite">关于伟班</p>
        <div @click.stop="pageJump('AboutUs', 4)">品牌故事</div>
        <div @click.stop="pageJump('AboutUs', 4)">发展历程</div>
        <div @click.stop="pageJump('AboutUs', 4)">企业文化</div>
      </div>
      <div class="css_labelcont">
        <p class="css_tite">热门产品</p>
        <div @click.stop="pageJump('VeiBan', 1)">伟班APP</div>
        <div @click.stop="pageJump('MES', 1)">MES</div>
        <div @click.stop="pageJump('CRM', 1)">CRM</div>
        <div @click.stop="pageJump('ERP', 1)">ERP</div>
      </div>
      <div class="css_labelcont">
        <p class="css_tite" @click.stop="pageJump('programme', 3)">行业方案</p>
        <div @click.stop="pageJump('Electronics', 3)">电子组装</div>
        <div @click.stop="pageJump('Mechanics', 3)">机械制造</div>
        <div @click.stop="pageJump('stamping', 3)">注塑冲压</div>
        <div @click.stop="pageJump('hardware', 3)">五金制造</div>
        <div @click.stop="pageJump('LED', 3)">LED制造</div>
        <div @click.stop="pageJump('furniture', 3)">家具制造</div>
        <div @click.stop="pageJump('Conduit', 3)">管道制造</div>
        <div @click.stop="pageJump('Cable', 3)">线缆制造</div>
        <div @click.stop="pageJump('automobile', 3)">汽配制造</div>
      </div>
      <div class="css_labelcont">
        <p class="css_tite">伟班资讯</p>
        <div @click.stop="pageJump('ApipeCloud', 3)">公司新闻</div>
        <div @click.stop="pageJump('Industry', 3)">行业动态</div>
        <div @click.stop="pageJump('Encyclopedias', 3)">伟班百科</div>
        <div @click.stop="pageJump('Cloudclass', 3)">伟班云课</div>
      </div>
      <div class="css_information">
        <div class="css_information2">
          <div>服务热线&nbsp;&nbsp;&nbsp;&nbsp;</div>
          <span>0755-86016600</span>
        </div>
        <div class="css_information2">
          <div>周一至周五:&nbsp;&nbsp;&nbsp;&nbsp;</div>
          <div>8:30~18:00</div>
        </div>
        <div class="css_information2">
          <div>公司邮箱:&nbsp;&nbsp;&nbsp;&nbsp;</div>
          <div>cs@veiban.com</div>
        </div>
        <div class="css_information3">
          <div style="margin-right:50px;">
            <!-- <img src="@/assets/ft/vxkf.png" /> -->
            <div style="margin-top: 10px;">扫码添加微信客服</div>
          </div>
          <div>
            <!-- <img src="@/assets/ft/kf.png" /> -->
            <div style="margin-top: 10px;">扫码添加QQ客服</div>
          </div>
        </div>
      </div>
    </div>
    <div class="css_bott">
      <div>友情链接</div>
      <div>华瀚科技控股</div>
      <div>CSDN</div>
      <div>工业4.0</div>
      <div>EEPW</div>
      <div>美云智数</div>
      <div>树根互联</div>
    </div>
    <div class="copyright">{{ text }}</div>

    <div class="copyright" style="margin-top:20px">
      <a
      style="color:white"
        rel="external nofollow"
        target="_blank"
        href="https://beian.miit.gov.cn/"
      >
        粤ICP 备 2021111388号 电子公告服务规则</a
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      text: "Copyright &#169 2021 深圳优制云工业互联网有限公司 版权所有",
    };
  },
  methods: {
    pageJump(name_, index) {
      if (index > 0) {
        // debugger
        let e = $(".nav > li > a")[index];
        $(e)
          .siblings()
          .removeClass("activeColor");
        $(e).addClass("activeColor");
      }
      this.$router.push({
        name: name_,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.css_bott {
  width: 80vw;
  display: flex;
  // justify-content: space-between;
  // color: #ffffff;
  height: 100px;
  margin-left: 12vw;
  padding-top: 30px;
}
.copyright {
  width: 80vw;
  height: 30px;
  font-size: 20px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  line-height: 30px;
}
.css_bott div {
  width: 120px;
  height: 40px;
  font-size: 20px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  line-height: 30px;
  margin-right: 60px;
}
.css_information2 {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.css_information2 > div {
  font-size: 22px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  margin-right: 10px;
}
.css_information3 {
  margin-top: 30px;
  display: flex;
}
.css_information3 > div {
  font-size: 16px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  color: #adb5c0;
}
.css_information2 > span {
  font-size: 30px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}
.css_cont {
  width: 80vw;
  // border: 1px solid red;
  display: flex;
  justify-content: space-evenly;
}
.css_information {
  margin-top: 85px;
}
.css_information > div {
  color: white;
}
.css_tite {
  // color: red;
  font-size: 20px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  text-align: left;
}
.css_labelcont {
  margin-top: 100px;
  color: white;
}
.css_labelcont > div {
  margin: 20px 0px;
  font-size: 16px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: left;
  color: #adb5c0;
  // border: 1px solid red;
  width: 100px;
}
.css_home {
  cursor: pointer;
  background: black;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>
