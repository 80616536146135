<template>
  <div>
    <div class="mobileFooter d-block d-lg-none">
      <div class="css_information">
        <div
          :class="['item']"
          @click="handleDeal(0)"
        >
          <div class="cc"></div>
          <div class="key-name">在线咨询</div>
        </div>

        <div
          :class="['item']"
          @click="handleDeal(1)"
          style="
            border: 1px solid #dfe1e6;
            border-top: none;
            border-bottom: none;
          "
        >
          <div class="aa"></div>
          <div class="key-name">
            <a href="tel:13808805396" ref="telPhone" style="text-decoration:none;color:black;">电话咨询</a>
            </div>
        </div>

        <div
          :class="['item', 'item-active-bb']"
          @click="handleDeal(2)"
        >
          <div class="bb"></div>
          <div class="key-name">免费试用</div>
        </div>
      </div>
    </div>
    <!-- 弹框 -->
    <div class="vueMdelBox" :hidden="!vueShowModel">
      <div class="vueContant">
        <div class="vueTitle" @click="confirm">
          <span class="el el-icon-close"></span>
        </div>
        <div class="main">
          <img style="width: 100px" src="../assets/newSite/code.png" />
          <div>微信扫码咨询客服</div>
        </div>
      </div>
      <!-- 背景黑色蒙版 -->
      <div
        class="vueBgdCol"
        :hidden="!vueShowModel"
        @click="vueShowModel = false"
      ></div>
    </div>

    <div class="vueMdelBox" :hidden="!vueShowModel2">
      <div class="vueContant">
        <div class="vueTitle" @click="confirm">
          <span class="el el-icon-close"></span>
        </div>
        <div class="main">
          <img style="width: 100px" src="../assets/newSite/site.png" />
          <div style="margin-top:10px;">请用电脑打开优制云官网</div>
          <div>注册免费试用产品</div>
        </div>
      </div>
      <!-- 背景黑色蒙版 -->
      <div
        class="vueBgdCol"
        :hidden="!vueShowModel2"
        @click="vueShowModel2 = false"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "进一步了解优制云产品及行业解决方案",
    },
  },
  data() {
    return {
      idx: -1,
      vueShowModel: false, //默认不显示
      vueShowModel2: false, //默认不显示
    };
  },
  methods: {
    confirm() {
      this.vueShowModel = false;
      this.vueShowModel2 = false;
    },

    handleDeal(index) {
      this.idx = index;
      if (index == 0) {
        this.vueShowModel = true;
        this.vueShowModel2 = false;
      }else if
        (index == 2) {
        this.vueShowModel = false;
        this.vueShowModel2 = true;
      }else{
        this.vueShowModel = false;
        this.vueShowModel2 = false;
      }
    },
    handleTry() {
      window.open("https://apipecloud.veiban.com/login");
    },
    handleSite() {
      window.open("https://epipe.cn");
    },
  },
};
</script>
<style lang="less" scoped>
.vueBgdCol {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 19;
  -moz-opacity: 0.6;
  opacity: 0.6;
  filter: alpha(opacity=88);
}

.vueContant {
  padding: 10px 0px 0px 0px;
  position: fixed;
  top: 36%;
  left: 50%;
  width: 80%;
  min-height: 220px;
  margin-left: -40%;
  background-color: white;
  z-index: 20;
  overflow: auto;
  border-radius: 10px;
  padding: 20px;
  font-size: 14px;
  .vueTitle {
    text-align: right;
  }
}

.mobileFooter {
  position: fixed;
  left: 0;
  bottom: 0;
  // border: 1px solid red;
  width: 100%;
  background: white;
  height: 80px;
  font-size: 14px;
  z-index: 100;
  .css_information {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    .item {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .key-name {
        margin-top: 5px;
      }
      .aa {
        width: 30px;
        height: 30px;
        margin: 0 auto;
        background-image: url("../assets/newSite/rightNav/tel.png");
        background-size: contain;
        background-position: center;
      }
      .bb {
        width: 30px;
        height: 30px;
        margin: 0 auto;
        background-image: url("../assets/newSite/rightNav/test.png");
        background-size: contain;
        background-position: center;
      }
      .cc {
        width: 30px;
        height: 30px;
        margin: 0 auto;
        background-image: url("../assets/newSite/rightNav/contact.png");
        background-size: contain;
        background-position: center;
      }
    }
    .item-active-aa {
      background: rgba(21, 132, 255, 1);
      color: white;
      .aa {
        background-image: url("../assets/newSite/rightNav/tel-s.png");
      }
    }
    .item-active-bb {
      background: rgba(21, 132, 255, 1);
      .bb {
        background-image: url("../assets/newSite/rightNav/test-s.png");
      }
      color: white;
    }
    .item-active-cc {
      background: rgba(21, 132, 255, 1);
      .cc {
        background-image: url("../assets/newSite/rightNav/contact-s.png");
      }
      color: white;
    }
  }
}
</style>
    