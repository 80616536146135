import http from "@/utils/http";
// const fixedPath = "http://192.168.3.149:8098";  //典礼
// const fixedPath = "http://192.168.3.66:8098"; 
const fixedPath = "https://admin.veiban.com";
 
export function clickzan(params) {
  return http.get(`${fixedPath}/cms/content/like.do`, params);
}
//是否点赞
export function isLike(params) {
  return http.get(`${fixedPath}/cms/content/like/check.do`, params);
}

//留言
export function leaveMessage(params) { 
  return http.post(`${fixedPath}/cms/feedback/save.do`, params);
}

//公司动态
export function Companydynamics(params) {
  return http.post(`${fixedPath}/cms/content/list.do`, params);
}
//行业动态
export function Industrydynamics(params) {
  return http.post(`${fixedPath}/cms/content/list.do`, params);
}
//动态导航栏
export function Dynamicnavigationbar(params) {
  return http.post(`${fixedPath}/cms/category/list.do`, params);
}
// 新闻列表
export function Newslist(params) {
  return http.post(`${fixedPath}/cms/content/list.do`, params);
}
// 新闻详情
export function Newsdetail(params) {
  return http.get(`${fixedPath}/cms/content/get.do`, params);
}
//提交咨询、注册
export function advisoryAPI(params) {
  return http.get(`${fixedPath}/mesBoard/addMesBoard`, params);
}
//提交试用申请
export function submitApplyAPI(params) {
  return http.post(`${fixedPath}/MUser/user/add`, params);
}
//获取验证码文案
export function getRegisteredAPI(params) {
  return http.get(`${fixedPath}/MUser/code`, params);
}
//获取验手机验证码
export function getPhoneCodeAPI(params) {
  return http.get(`${fixedPath}/MUser/sendSms`, params);
}
//验证手机验证码
export function verifyPhoneCodeAPI(params) {
  return http.get(`${fixedPath}/MUser/codeVali`, params);
}
//获取用户信息
export function getUserInfo(params) {
  return http.get(`${fixedPath}/api/wpa/user/`, params);
}

//对URL后面的参数进行拼接
export function queryString(params, postFlag) {
  let paramUrl = "";
  for (let key in params) {
    if (!postFlag && paramUrl == "") {
      paramUrl += "?" + key + "=" + params[key];
    } else {
      paramUrl += "&" + key + "=" + params[key];
    }
  }
  return paramUrl;
}
export default fixedPath