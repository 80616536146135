<template>
  <div>
    <div class="css_conttite">
      <div class="css_tite">行业解决方案</div>
      <div class="css_titetab">
        <div>
          <router-link to="Electronics">电子组装</router-link>
        </div>
        <div>
          <router-link to="Mechanics">机械制造</router-link>
        </div>
        <div>
          <router-link to="stamping">注塑冲压</router-link>
        </div>
        <div>
          <router-link to="hardware">五金制造</router-link>
        </div>
        <div style="margin-left:40px">
          <router-link to="automobile">汽车配件制造</router-link>
        </div>
        <div style="margin-left:32px">
          <router-link to="LED">LED制造</router-link>
        </div>
        <div>
          <router-link to="furniture">家具制造</router-link>
        </div>
        <div>
          <router-link to="Conduit">管道制造</router-link>
        </div>
        <div>
          <router-link to="Cable">线缆制造</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
a {
  text-decoration: none;
  color: #999999;
}

a:hover {
  color: #1584ff;
}
.router-link-active {
  text-decoration: none;
  color: #999999;
}
.css_titetab div {
  margin: 16px 50px;
  font-size: 14px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
}
.css_titetab {
  margin: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  width: 600px;
  // border: 1px solid red;
}

.css_conttite {
  display: flex;
  width: 60vw;
  border-bottom: 1px solid #ebebeb;
}
.css_tite {
  width: 130px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  color: #333333;
}
</style>
