import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueImg from "v-img";
// import Bootstrap from "bootstrap";
// import "../node_modules/bootstrap/dist/css/bootstrap.css";
// import "../node_modules/bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/css/bootstrap.min.css";

import "bootstrap/dist/js/bootstrap.min";
// import 'amfe-flexible';
import RightNav from "@/components/RightNav/index.vue";
import RightNavNew from "@/components/RightNavNew/index.vue";
import TopNav from "@/components/TopNav/index.vue";
import TopNavNew from "@/components/TopNavNew/index.vue";
import Footer from "@/components/footer.vue";
import NewFooter from "@/components/footernew.vue";
import MobileFooter from "@/components/mobileFooter.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.component("RightNav", RightNav);
Vue.component("RightNavNew", RightNavNew);
Vue.component("TopNav", TopNav);
Vue.component("TopNavNew", TopNavNew);

Vue.component("Footer", Footer);
Vue.component("NewFooter", NewFooter);
Vue.component("MobileFooter", MobileFooter);
Vue.config.productionTip = false;
const vueImgConfig = {
  altAsTitle: true,
};

Vue.prototype.$BaseUrl = 'https://admin.veiban.com'
// Vue.prototype.$BaseUrl = 'http://192.168.3.66:8098' 

Vue.use(VueImg, vueImgConfig);
// Vue.use(Bootstrap);
Vue.use(ElementUI);

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
Vue.prototype.$axios = axios;
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
