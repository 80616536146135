<template>
  <div>
    <nav
      :class="[
        'navbar',
        'fixed-top',
        isTop ? 'whiteStyle' : 'bg-body-tertiary',
        'navbar-expand-lg',
        'd-none',
        'd-lg-block',
      ]"
      style="padding: 0 20px"
    >
      <div class="d-none d-lg-block">
        <div
          v-show="hoverKey == 1"
          class="product-borad"
          @mouseenter="handleMouseEnter(1)"
          @mouseleave="handleMouseLeave"
        >
          <div class="main-conten">
            <div class="main-plan">
              <div>
                <img class="pic" src="@/assets/newSite/pic1.png" alt="" />
              </div>
              <div class="content">
                <div class="items">
                  <div>伟班(OA)</div>
                  <div>HR系统</div>
                  <div>会议系统</div>
                  <div>日报系统</div>
                  <div>费控系统</div>
                </div>
                <div class="items">
                  <div>考勤系统</div>
                  <div>薪酬系统</div>
                  <div>DMS系统</div>
                  <div>企业运营监管大脑</div>
                  <div
                    style="color: #1584ff"
                    @click="
                      () => {
                        this.$router.push('/smartOffice');
                      }
                    "
                  >
                    了解详情..
                  </div>
                </div>
              </div>
            </div>
            <div class="main-plan">
              <div>
                <img class="pic" src="@/assets/newSite/pic3.png" alt="" />
              </div>
              <div class="content">
                <div class="items">
                  <div>优制云MES</div>
                  <div>优制云CRM</div>
                </div>
                <div class="items">
                  <div>优制云WMS</div>
                  <div
                    style="color: #1584ff"
                    @click="
                      () => {
                        this.$router.push('/smartMade');
                      }
                    "
                  >
                    了解详情..
                  </div>
                </div>
              </div>
            </div>
            <div class="main-plan">
              <div>
                <img class="pic" src="@/assets/newSite/pic2.png" alt="" />
              </div>
              <div class="content">
                <div class="items">
                  <div class="tit">招商运营</div>
                  <div class="tit">企业服务</div>
                  <div class="tit">数字办公</div>
                  <div class="tit">楼栋管理</div>
                  <div class="tit">资产管理</div>
 
                  <div
                    style="color: #1584ff"
                    @click="
                      () => {
                        this.$router.push('/smartPark');
                      }
                    "
                  >
                    了解详情..
                  </div>
                </div>
                <div class="items">
                  <div class="tit">物业服务</div>
                  <div class="tit">园区安防</div>
                  <div class="tit">便捷通行</div> 
                  <div class="tit">楼宇管理</div> 
                  <div class="tit">设施管理</div> 
                  <div class="tit">能效管理</div> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="resolve-borad"
          v-show="hoverKey == 2"
          @mouseenter="handleMouseEnter(2)"
          @mouseleave="handleMouseLeave"
        >
          <div class="main-conten">
            <div class="ways">
              <!-- <span class="item">智能制造-解决方案</span>
              <span class="item">智能办公-解决方案</span>
              <span class="item">智慧园区-解决方案</span> -->
            </div>
          </div>
        </div>
        <div
          class="about-borad"
          v-show="hoverKey == 4"
          @mouseenter="handleMouseEnter(4)"
          @mouseleave="handleMouseLeave"
        >
          <div class="main-conten">

          </div>
        </div>
      </div>
      <div class="container-fluid">
        <a class="navbar-brand" href="/home">
          <img
            v-show="isTop"
            src="@/assets/newSite/logodefault.png"
            style="width: 124px; height: 40px"
            alt=""
          />
          <img
            v-show="!isTop"
            src="@/assets/newSite/logoactive.png"
            style="width: 124px; height: 40px"
            alt=""
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse">
          <ul
            class="navbar-nav mb-lg-0"
            style="width: 100%; justify-content: end"
          >
            <li
              style="cursor: pointer"
              class="nav-item nav-toggle"
              @mouseenter="handleMouseEnter(0)"
              @mouseleave="handleMouseLeave"
              :class="{ hover: hoverKey == 0 }"
              @click="
                () => {
                  this.$router.push('/');
                }
              "
            >
              <a class="nav-link active">首页</a>
            </li>
            <li
              class="nav-item nav-toggle"
              @mouseenter="handleMouseEnter(1)"
              @mouseleave="handleMouseLeave"
              :class="{ hover: hoverKey == 1 }"
              @click="
                () => {
                  // this.$router.push('/VeiBan');
                }
              "
            >
              <a class="nav-link" href="javascript:;"> 产品中心 </a>
              <img
                class="css_translateZ"
                :src="isTop ? downImgw : downImg"
                alt=""
              />
            </li>

            <li
              class="nav-item nav-toggle nali"
              @mouseenter="handleMouseEnter(2)"
              @mouseleave="handleMouseLeave"
              :class="{ hover: hoverKey == 2 }"
              @click.stop="handleSolution"
            >
              <a class="nav-link" href="javascript:;">解决方案 </a>
              <img
                class="css_translateZ"
                :src="isTop ? downImgw : downImg"
                alt=""
              />
              <ul class="ul" style="left:-65px;" v-show="hoverKey == 2">
                <li @click="handleMade()">智能制造-解决方案</li>
                <li @click="handleOffice()">智能办公-解决方案</li>
                <li @click="handlePark()">智慧园区-解决方案</li>
              </ul>
            </li>
            <li
              class="nav-item nav-toggle"
              @mouseenter="handleMouseEnter(3)"
              @mouseleave="handleMouseLeave"
              :class="{ hover: hoverKey == 3 }"
              @click="
                () => {
                  this.$router.push('/Case');
                }
              "
            >
              <a class="nav-link" href="javascript:;">客户案列</a>
            </li>
            <li
              class="nav-item nav-toggle nali"
              @mouseenter="handleMouseEnter(4)"
              @mouseleave="handleMouseLeave"
              :class="{ hover: hoverKey == 4 }"
              @click="
                () => {
                  // this.$router.push('/AboutUs');
                }
              "
            >
              <a class="nav-link" href="javascript:;">了解我们 </a>
              <img
                class="css_translateZ"
                :src="isTop ? downImgw : downImg"
                alt=""
              />

              <ul class="ul" v-show="hoverKey == 4">
                <li @click.stop="handleBrif"> 公司概述&nbsp;&nbsp;</li>
                <li @click.stop="handleNews">新闻资讯&nbsp;&nbsp;</li>
                <li @click.stop="handleTalent">人才引进&nbsp;&nbsp;</li>
              </ul>
            </li>
            <li
              class="nav-item nav-toggle"
              @mouseenter="handleMouseEnter(5)"
              @mouseleave="handleMouseLeave"
              :class="{ hover: hoverKey == 5 }"
              @click="
                () => {
                  this.$router.push('/Contact');
                }
              "
            >
              <a class="nav-link" href="javascript:;">联系我们</a>
            </li>
            <li class="nav-item opts">
              <span @click="handleLogin" :class="['btnLogin', isTop ? '' : 'active']">登录</span>
              <span @click="handleLogin" :class="['btnRegister']">注册</span>
            </li>
            <!-- <li class="nav-item opts">
              <span :class="['btnRegister']">注册</span>
            </li> -->
          </ul>
        </div>
      </div>

      <div class="d-block d-lg-none defineBoard">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <el-collapse
            v-model="activeNames"
            @change="handleChange"
            class="inner_collapse"
          >
            <div class="single-item"  >首页</div>
            <el-collapse-item title="产品中心" name="1">
              <div class="tit">MES</div>
              <div class="tit">WMS</div>
              <div class="tit">SCM</div>
              <div class="tit">CRM</div>
            </el-collapse-item>
            <el-collapse-item title="解决方案" name="2">
              <div class="tit">智能办公解决方案</div>
              <div class="tit">智能制造解决方案</div>
              <div class="tit">智慧园区解决方案</div>
            </el-collapse-item>
            <div class="single-item">客户案例</div>
            <el-collapse-item title="了解我们" name="3">
              <div class="tit">SCM</div>
              <div class="tit">CRM</div>
            </el-collapse-item>
            <div class="single-item">联系我们</div>
          </el-collapse>
        </div>
      </div>
    </nav>

    <!-- 导航 手机端 -->
    <nav
      class="navbar sticky-top bg-body-tertiary navbar-expand-lg d-block d-lg-none"
      v-if="true"
    >
      <div class="container-fluid">
        <a class="navbar-brand" href="/">
          <img
            src="@/assets/newSite/logoactive.png"
            alt=""
            style="width: 100px"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContentss"
          aria-controls="navbarSupportedContentss"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div class="d-block d-lg-none defineBoard">
        <div class="collapse navbar-collapse" id="navbarSupportedContentss">
          <el-collapse
            v-model="activeNames"
            @change="handleChange"
            class="inner_collapse"
          >
            <div class="single-item activepress" @click="hanleHome()">首页</div>
            <el-collapse-item  class="first fis" title="产品中心" name="1">
              <el-collapse style="padding-left:10px;border-bottom:none;">
                <el-collapse-item title="智能办公" name="a">
                 <div style="padding-left:10px;">
                  <div class="tit">伟班(OA)</div>
                  <div class="tit">HR系统</div>
                  <div class="tit">会议系统</div>
                  <div class="tit">日报系统</div>
                  <div class="tit">费控系统</div>
                  <div class="tit">考勤系统</div>
                  <div class="tit">薪酬系统</div>
                  <div class="tit">DMS系统</div>
                  <div class="tit">企业运营监管大脑</div>
                  <div class="tit activepress" style="color: #1584ff" @click="()=>{this.$router.push('/smartOffice')}">了解详情..</div>
                 </div>
                </el-collapse-item>
                <el-collapse-item title="智能制造" name="b">
                  <div style="padding-left:10px;">
                  <div class="tit">优制云MES</div>
                  <div class="tit">优制云WMS</div>
                  <div class="tit">优制云CRM</div>
                  <div class="tit activepress" style="color: #1584ff" @click="()=>{this.$router.push('/smartMade')}">了解详情..</div>
                </div>
                </el-collapse-item>
                <el-collapse-item title="智慧园区" name="c">
                  <div style="padding-left:10px;">
                  <div class="tit">招商运营</div>
                  <div class="tit">企业服务</div>
                  <div class="tit">数字办公</div>
                  <div class="tit">楼栋管理</div>
                  <div class="tit">资产管理</div>
                  <div class="tit">物业服务</div>
                  <div class="tit">园区安防</div>
                  <div class="tit">便捷通行</div> 
                  <div class="tit">楼宇管理</div> 
                  <div class="tit">设施管理</div> 
                  <div class="tit">能效管理</div> 
                  <div class="tit activepress" style="color: #1584ff" @click="()=>{this.$router.push('/smartPark')}">了解详情..</div>
                </div>
                </el-collapse-item>
              </el-collapse>
            </el-collapse-item>
            <el-collapse-item class="first" title="解决方案" name="2">
              <div style="padding-left:10px;">
              <div class="tit activepress" @click="handleOffice()">智能办公解决方案</div>
              <div class="tit activepress" @click="handleMade()">智能制造解决方案</div>
              <div class="tit activepress" @click="handlePark()">智慧园区解决方案</div>
            </div>
            </el-collapse-item>
            <div class="single-item activepress" @click="()=>{this.$router.push('/Case');}">客户案例</div>
            <el-collapse-item class="first" title="了解我们" name="3">
              <div style="padding-left:10px;">
              <div class="tit activepress" @click.stop="handleBrif">公司概述</div>
              <div class="tit activepress" @click.stop="handleNews">新闻资讯</div>
              <div class="tit activepress" @click.stop="handleTalent">人才引进</div> 
            </div>
            </el-collapse-item>
            <div class="single-item activepress" @click="()=>{this.$router.push('/Contact');}">联系我们</div>
          </el-collapse>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import Mod from "@/components/Navigation/mod";
import Solution from "@/components/Navigation/Solution";
import Weibaninformation from "@/components/Navigation/Weibaninformation";

export default {
  components: {
    Mod,
    Solution,
    Weibaninformation,
  },
  data() {
    return {
      hoverKey: -1,
      activeNames: ["1"],
      RoutName: "",
      isTop: true,
      downImg: require("@/assets/down.png"),
      downImgw: require("@/assets/newSite/down.png"),
    };
  },

  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.scrolling);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrolling);
  },
  created() {
    this.RoutName = this.$route.name;
  },
  methods: {
    handleLogin(){
     window.open('https://apipecloud.veiban.com/login')
    },
    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // console.log("header 滚动距离 ", scrollTop);
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;
      //变量windowHeight是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight) {
        //你想做的事情
        // console.log("header  你已经到底部了");
      }
      if (scrollStep < 0) {
        // console.log("header 滚动条向上滚动了！");
      } else {
        // console.log("header  滚动条向下滚动了！");
        this.isTop = false;
      }
      // 判断是否到了最顶部
      if (scrollTop <= 0) {
        // console.log("header 到了最顶部");
        this.isTop = true;
      }
    },
   hanleHome(){ 
      this.$router.push('/') 
   },
    handleBrif() {
      console.log(1);
      this.$router.push("/AboutUs");
    },
    handleNews() {
      console.log(2);
      this.$router.push("/News");
    },
    handleTalent() {
      console.log(3);
      this.$router.push("/Company");
    },
    handleChange() {},
    handleMouseEnter(val) {
      this.hoverKey = val;
      this.isTop = false;
    },
    handleMouseLeave() {
      this.hoverKey = -1;
      this.isTop = true;
    },
    handleSolution() {
      // this.$router.push("/Solution");
    },
    handleMade() {
      this.$router.push("/madeSolution");
    },
    handleOffice() {
      this.$router.push("/officeSolution");
    },
    handlePark() {
      this.$router.push("/parkSolution");
    },
  },
};
</script>

<style lang="less"  >

.activepress:active{
  color: #262626 !important;
  background-color: #eee !important;
}

.first >div>div{
  font-weight: 700;
}
  .el-collapse-item__content{
  padding-bottom: 0 !important;
}
.whiteStyle {
  a {
    color: white !important;
    font-family: Source Han Sans CN;
  }
}
.nali {
  position: relative;
  .ul {
    padding-top: 10px;
    position: absolute;
    bottom: -100px;
    left: -70px;
    width: 200px;
    li {
      cursor: pointer;
      margin: 8px 0;
      font-size: 14px;
      &:hover {
        text-decoration: underline;
        color: #1584ff;
      }
    }
  }
}
.opts {
  display: flex;
  align-items: center;
  .btnLogin {
    font-size: 14px;
    width: 80px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: white;
    border: 1px solid white;
    margin-right: 20px;
    box-sizing: border-box;
    cursor: pointer;
    &:hover{
      color: #1484ff !important;
      border: 1px solid #1484ff !important;
    }
  }
  .active {
    color: black !important;
    border: 1px solid black !important;
  }
  .btnRegister {
    cursor: pointer;
    box-sizing: border-box;
    font-size: 14px;
    width: 80px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: white;
    background: #1484ff;
    &:hover{
      background: #0a58ca;
    }
  }
}

.navbar {
  .el-collapse-item__header {
    padding: 0 10px !important;
  }
  .product-borad {
    // opacity: 0.65;
    position: absolute;
    background: white;
    // border: 1px solid red;
    width: 100%;
    overflow: hidden;
    height: 400px;
    top: 72px;
    text-align: left;
    left: 0;
    box-sizing: border-box;
    .main-conten {
      width: 1200px;
      margin: 0 auto;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      .pic {
        width: 395px;
        height: 160px;
      }
      .content {
        padding: 0 20px;
        display: flex;
        .items {
          flex: 1;
          div {
            padding: 5px 0;
            color: #666666;
            font-size: 14px;
            font-family: "思源黑体";
            &:hover {
              cursor: pointer;
              color: #1584ff;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .resolve-borad {
    // opacity: 0.6;
    position: absolute;
    background: white;
    // border: 1px solid red;
    width: 100%;
    overflow: hidden;
    height: 120px;
    top: 72px;
    text-align: left;
    left: 0;
    box-sizing: border-box;
    .main-conten {
      width: 1200px;
      margin: 0 auto;
      padding: 20px;
      display: flex;
      position: relative;
      // justify-content: space-between;
      .ways {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 402px;
        top: 22px;
        .item {
          padding: 5px 0;
          color: #666666;
          font-size: 14px;
          font-family: "思源黑体";
          &:hover {
            cursor: pointer;
            color: #1584ff;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .about-borad {
    // opacity: 0.6;
    position: absolute;
    background: white;
    // border: 1px solid red;
    width: 100%;
    overflow: hidden;
    height: 120px;
    top: 72px;
    text-align: left;
    left: 0;
    box-sizing: border-box;
    .main-conten {
      width: 1200px;
      margin: 0 auto;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      div {
        padding: 5px 0;
        color: #666666;
        font-size: 14px;
        font-family: "思源黑体";
        &:hover {
          cursor: pointer;
          color: #1584ff;
          text-decoration: underline;
        }
      }
    }
  }

  .defineBoard {
    width: 100%;
    .inner_collapse {
      margin-top: 10px;
      .single-item {
        background: white;
        height: 48px;
        line-height: 48px;
        text-align: left;
        font-size: 13px;
        padding: 0 10px;
        cursor: pointer;
        font-weight: 700;
      }
      .el-collapse-item {
        background-color: #f8f9fa;
        .el-collapse-item__header {
          padding: 0 10px;
        }
        .tit {
          text-align: left;
          padding: 4px 10px;
        }
      }
    }
  }

  .nav-item {
    margin: 0 2rem;
    position: relative;
    height: 72px;
    a {
      color: black;
    }
  }

  .nav-item.hover {
    transition: all 0.2s ease-in;
    .css_translateZ {
      transition: all 0.2s ease-out;
      transform: rotate(180deg);
    }
  }

  .nav-item::after {
    content: "";
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    width: 100%;
    background: #262626;
    transform: scaleX(0);
    transition: transform 0.2s ease-in-out;
  }

  .nav-item::after {
    bottom: 0;
    transform-origin: center right;
  }

  .nav-item.hover::after {
    transform-origin: center left;
    transform: scaleX(1);
  }

  .nav-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .css_translateZ {
    width: 24px;
    height: 24px;
  }
}
</style>
