<template>
  <div>
    <div class="css_conttite">
      <div class="css_tite">伟班APP</div>
      <div class="css_titetab">
        <div>
          <router-link to="/VeiBan">智能考勤</router-link>
        </div>
        <div>
          <router-link to="/VeiBan">档案管理</router-link>
        </div>
        <div>
          <router-link to="/VeiBan">智能通讯录</router-link>
        </div>
        <div>
          <router-link to="/VeiBan">企业公告</router-link>
        </div>
        <div>
          <router-link to="/VeiBan">会议管理</router-link>
        </div>
        <div>
          <router-link to="/VeiBan">绩效考核</router-link>
        </div>
        <div>
          <router-link to="/VeiBan">审批管理</router-link>
        </div>
      </div>
    </div>
    <div class="css_conttite">
      <div class="css_tite">MES</div>
      <div class="css_titetab">
        <div>
          <router-link to="/MES"
            >助力企业打造智能工厂，全程透明看得见</router-link
          >
        </div>
      </div>
    </div>
    <div class="css_conttite">
      <div class="css_tite">CRM</div>
      <div class="css_titetab">
        <div>
          <router-link to="/CRM"
            >建立客户价值体系，助力一站式智能成交</router-link
          >
        </div>
      </div>
    </div>
    <div class="css_conttite">
      <div class="css_tite">ERP</div>
      <div class="css_titetab">
        <div>
          <router-link to="/ERP">全方位企业信息管理工具</router-link>
        </div>
      </div>
    </div>
    <div class="css_conttite">
      <div class="css_tite">WMS</div>
      <div class="css_titetab">
        <div>
          <router-link to="/WMS">聚焦物流全流程追溯可视，打造高效供应链履约体系</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
a {
  text-decoration: none;
  color: #999999;
}

a:hover {
  color: #1584ff;
}
.router-link-active {
  text-decoration: none;
  color: #999999;
}
.css_titetab div {
  margin: 16px 30px;
  font-size: 14px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
}
.css_titetab {
  margin: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  width: 600px;
  // border: 1px solid red;
}

.css_conttite {
  display: flex;
  width: 60vw;
  border-bottom: 1px solid #ebebeb;
}
.css_tite {
  width: 130px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  color: #333333;
}
</style>
