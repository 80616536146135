<template>
  <div>
    <div class="css_conttite">
      <div class="css_tite">伟班资讯</div>
      <div class="css_titetab">
        <div
          v-for="(item, index) in dynamic"
          :key="index"
          @click="onrouter(item)"
        >
          <router-link to="">{{ item.categoryTitle }}</router-link>
        </div>

        <div>
          <router-link to="/Cloudclass">伟班云课</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Dynamicnavigationbar } from "@/api/fetch";
import Qs from "qs";
export default {
  data() {
    return {
      dynamic: [],
    };
  },
  created() {
    this.getNewsList();
  },
  methods: {
    onrouter(name_) {
      if (name_.id == "5") {
        if (name_.categoryTitle) {
          // 新闻
          this.$router.push({
            path: `/currency/${name_.id}`,
          });
        }
      }
      if (name_.id == "6") {
        if (name_.categoryTitle) {
          // 新闻
          this.$router.push({
            path: `/Industry/${name_.id}`,
          });
        }
      }
      if (name_.id == "1458727809170264065") {
        if (name_.categoryTitle) {
          // 百科
          this.$router.push({
            path: `/Encyclopedias/${name_.id}`,
          });
        }
      }
    },
    getNewsList() {
      let data = {
        categoryId: "2",
      };
      Dynamicnavigationbar(Qs.stringify(data)).then((result) => {
        this.dynamic = result.data.rows;
      });
    },
  },
};
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
  color: #999999;
}

a:hover {
  color: #1584ff;
}
.router-link-active {
  text-decoration: none;
  color: #999999;
}
.css_titetab div {
  margin: 16px 50px;
  font-size: 14px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
}
.css_titetab {
  margin: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  width: 600px;
  // border: 1px solid red;
}

.css_conttite {
  display: flex;
  width: 60vw;
  border-bottom: 1px solid #ebebeb;
}
.css_tite {
  width: 130px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  color: #333333;
}
</style>
