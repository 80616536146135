<template>
  <div id="right-nav-page">
    <ul class="icons">
      <li class="tel phone">
        <div class="aa"></div>
        <div style="font-size: 12px; margin: 8px 0">电话咨询</div>
        <p><span>13808805396</span></p>
      </li>
      <li
        class="tel test"
        @mouseenter="handleMouseEnter(2)"
        @mouseleave="handleMouseLeave"
        @click="handleTest"
      >
        <div class="bb"></div>
        <div style="font-size: 12px; margin: 8px 0">免费试用</div>
      </li>
      <li
        class="tel contact"
        @mouseenter="handleMouseEnter(3)"
        @mouseleave="handleMouseLeave"
      >
        <div class="cc"></div>
        <div style="font-size: 12px; margin: 8px 0">在线咨询</div>
        <p>
          <img style="width: 100px" src="../../assets/newSite/code.png" />
        </p>
      </li>

      <li
        class="wechat store"
        @mouseenter="handleMouseEnter(4)"
        @mouseleave="handleMouseLeave"
        @click="handleSite"
      >
        <div class="dd"></div>
        <div style="font-size: 12px; margin: 8px 0">优管商城</div>
        <!-- <p><img  src="../../assets/components/Wechat.png"/></p> -->
      </li>

      <li
        class="mes message"
        @mouseenter="handleMouseEnter(3)"
        @mouseleave="handleMouseLeave"
        @click="handleLeaveMeassge"
      >
        <div class="ff"></div>
        <div style="font-size: 12px; margin: 8px 0">留言</div>
        <div class="formWrap" v-show="dialogVisible">
          <div class="tit">
            <span>留言</span>
            <span
              style="margin-top: 4px"
              class="el el-icon-close"
              @click.stop="
                () => {
                  dialogVisible = false;
                }
              "
            ></span>
          </div>
          <div class="form">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="联系人" prop="contactName">
                <el-input v-model="ruleForm.contactName"></el-input>
              </el-form-item>
              <el-form-item label="联系电话" prop="contactPhone">
                <el-input v-model="ruleForm.contactPhone"></el-input>
              </el-form-item>
              <el-form-item label="企业名称" prop="companyName">
                <el-input v-model="ruleForm.companyName"></el-input>
              </el-form-item>
              <el-form-item label="地址信息" prop="address">
                <el-input v-model="ruleForm.address"></el-input>
              </el-form-item>

              <el-form-item label="留言信息" prop="feedbackMessage">
                <el-input
                  type="textarea"
                  v-model="ruleForm.feedbackMessage"
                ></el-input>
              </el-form-item>

              <el-form-item style="text-align: right">
                <el-button type="primary" @click="submitForm('ruleForm')"
                  >确定</el-button
                >
                <el-button @click.stop="hanldeCancel">取消</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </li>

      <li class="tel back" ee @click.stop="backToTop">
        <div class="ee"></div>
        <div style="font-size: 12px; margin: 8px 0">返回顶部</div>
      </li>
    </ul>
  </div>
</template>
  <script>
import { leaveMessage } from "@/api/fetch";
import { isvalidPhone } from "@/utils/common";

import Qs from "qs";
export default {
  props: {
    routerLink: {
      type: String,
      default: "0",
    },
    heightValue: {
      type: Number,
      default: 450,
    },
  },
  data() {
    const validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入电话号码"));
      } else if (!isvalidPhone(value)) {
        //判断用户输入的值是否符合规则
        callback(new Error("请输入正确的11位手机号码"));
      } else {
        callback();
      }
    };

    return {
      interval: null,
      isMoving: false,
      dialogVisible: false,
      isFocus: false,
      ruleForm: {
        contactName: "",
        contactPhone: "",
        companyName: "",
        feedbackMessage: "",
        address: "",
      },
      rules: {
        contactName: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        contactPhone: [{ required: true, validator: validPhone }],
        companyName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        feedbackMessage: [
          { required: true, message: "请填写留言信息", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  methods: {
    hanldeCancel() {
      this.dialogVisible = false;

      this.resetForm("ruleForm");
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const prams = Qs.stringify(this.ruleForm);
          leaveMessage(prams)
            .then((res) => {
              this.$message.success("留言成功！");
              this.resetForm("ruleForm");
              this.dialogVisible = false;
            })
            .catch((e) => {
              console.log(e, 999);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    handleClose() {
      this.dialogVisible = false;
    },
    handleLeaveMeassge() {
      this.dialogVisible = true;
    },
    handleTest() {
      window.open("https://apipecloud.veiban.com/login");
    },
    handleSite() {
      window.open("https://epipe.cn");
    },
    handleMouseEnter() {
      this.isFocus = true;
    },
    handleMouseLeave() {
      this.isFocus = false;
    },

    backToTop() {
      if (this.isMoving) return;
      const start = window.pageYOffset;
      let i = 0;
      this.isMoving = true;
      this.interval = setInterval(() => {
        const next = Math.floor(this.easeInOutQuad(10 * i, start, -start, 500));
        if (next <= 0) {
          window.scrollTo(0, 0);
          clearInterval(this.interval);
          this.isMoving = false;
        } else {
          window.scrollTo(0, next);
        }
        i++;
      }, 16.7);
    },
    easeInOutQuad(t, b, c, d) {
      if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
      return (-c / 2) * (--t * (t - 2) - 1) + b;
    },
  },
};
</script>
  
  <style scoped lang="less">
#right-nav-page {
  .aa {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    background-image: url("../../assets/newSite/rightNav/tel.png");
    background-size: contain;
    background-position: center;
  }
  .bb {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    background-image: url("../../assets/newSite/rightNav/test.png");
    background-size: contain;
    background-position: center;
  }
  .cc {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    background-image: url("../../assets/newSite/rightNav/contact.png");
    background-size: contain;
    background-position: center;
  }
  .dd {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    background-image: url("../../assets/newSite/rightNav/store.png");
    background-size: contain;
    background-position: center;
  }
  .ee {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    background-image: url("../../assets/newSite/rightNav/back.png");
    background-size: contain;
    background-position: center;
  }
  .ff {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    background-image: url("../../assets/newSite/rightNav/message.png");
    background-size: contain;
    background-position: center;
  }

  .message:hover {
    .ff {
      background-image: url("../../assets/newSite/rightNav/message-s.png");
    }
  }

  .phone:hover {
    .aa {
      background-image: url("../../assets/newSite/rightNav/tel-s.png");
    }
  }
  .test:hover {
    .bb {
      background-image: url("../../assets/newSite/rightNav/test-s.png");
    }
  }
  .contact:hover {
    .cc {
      background-image: url("../../assets/newSite/rightNav/contact-s.png");
    }
  }
  .store:hover {
    .dd {
      background-image: url("../../assets/newSite/rightNav/store-s.png");
    }
  }
  .back:hover {
    .ee {
      background-image: url("../../assets/newSite/rightNav/back-s.png");
    }
  }

  position: fixed;
  top: 45%;
  right: 0px;
  width: 80px;
  // width: 50px;
  height: 200px;
  // -moz-opacity: 0.7;
  // opacity: 0.7;
  z-index: 150;
  ul.icons {
    position: absolute;
    z-index: 180;
    list-style: none;
    right: 10px;
    padding: 0;
    width: 80px;
    border: 1px solid #dfe1e6;
    border-right: none;
    //   background: #111;
    background: white;
    //   -moz-border-radius: 10px 0 0 10px;
    //   -webkit-border-radius: 10px 0 0 10px;
    //   border-radius: 10px 0 0 10px;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    li {
      // margin-bottom: 10px ;
      padding-top: 10px;
      z-index: 185;
      border-bottom: 1px solid #dfe1e6;
      cursor: pointer;
      position: relative;
      i {
        display: block;
        position: relative;
        width: 50px;
        height: 50px;
        //   background: url(../../assets/components/icons.png) no-repeat;
        cursor: pointer;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        -ms-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        z-index: 110;
      }
      p {
        display: block;
        position: absolute;
        top: 0;
        right: -260px;
        padding: 15px 20px;
        height: auto;
        color: White;
        line-height: 30px;
        border: 1px solid #dfe1e6;
        //   border-right: none;
        background: #111;
        -moz-border-radius: 10px;
        -webkit-border-radius: 10px;
        border-radius: 10px;
        -moz-opacity: 0;
        opacity: 0;
        filter: alpha(opacity=0);
        -webkit-transition: all 0.4s ease-in-out 0.1s;
        -moz-transition: all 0.4s ease-in-out 0.1s;
        -ms-transition: all 0.4s ease-in-out 0.1s;
        -o-transition: all 0.4s ease-in-out 0.1s;
        transition: all 0.4s ease-in-out 0.1s;
        overflow: hidden;
        z-index: 100;
      }
    }
    li:hover {
      color: white;
      background: #1484ff;
    }
    li.tel {
      p {
        width: 200px;
        //   margin-top: -46px;

        font-family: inherit;
        color: black;
        background: #ffffff;
        span {
          font-size: 20px;
        }
      }
      i {
        //   background-position: -62px -108px;
      }
      &:hover p {
        z-index: 100;
        right: 90px !important;
        opacity: 1;
      }
    }
    li.wechat {
      p {
        width: 200px;
        img {
          width: 115px;
          border: 2px solid White;
        }
      }
      i {
        background-position: -59px -66px;
      }
      &:hover p {
        z-index: 140;
        right: 50px;
        opacity: 1;
      }
    }
    li.mes {
      p {
        width: 200px;
        img {
          width: 115px;
          border: 2px solid White;
        }
      }
      i {
        background-position: -59px -66px;
      }
      &:hover p {
        z-index: 140;
        right: 50px;
        opacity: 1;
      }
      .formWrap {
        color: black;
        position: absolute;
        border: 1px solid #dfe1e6;
        right: 100px;
        top: -300px;
        width: 450px;
        // height: 300px;
        background: white;
        border-radius: 10px;
        .tit {
          display: flex;
          justify-content: space-between;
          padding: 10px 20px;
          border-bottom: 1px solid #eee;
        }
        .form {
          padding: 20px 40px 0 20px;
        }
      }
    }
    li.up {
      z-index: 500;
      i {
        background-position: -63px -150px;
      }
    }
  }

  a.switch {
    position: absolute;
    display: block;
    top: 270px;
    right: 11px;
    width: 26px;
    height: 26px;
    // background: url(../../assets/components/icons.png) no-repeat 0px -640px;
    cursor: pointer;
    -webkit-transition-delay: 0.15s;
    -moz-transition-delay: 0.15s;
    -ms-transition-delay: 0.15s;
    -o-transition-delay: 0.15s;
    transition-delay: 0.15s;
  }
  a.switch.off {
    background-position: -32px -640px;
  }
  .close {
    z-index: 50;
  }
  &.close ul.icons {
    right: -70px;
  }
}
</style>
  