<template>
  <div id="right-nav-page">
    <ul class="icons">
      <li class="tel">
        <i></i>
        <p>咨询热线：<br /><span>13808805038</span></p>
      </li>
      <li class="wechat">
        <i></i>
        <!-- <p>
          在线咨询<br /><img
            src="../../assets/components/Wechat.png"
            alt="扫描二维码在线咨询客服"
          />
        </p> -->
      </li>
      <li class="up" @click.stop="backToTop"><i></i></li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    routerLink: {
      type: String,
      default: "0",
    },
    heightValue: {
      type: Number,
      default: 450,
    },
  },
  data() {
    return {
      interval: null,
      isMoving: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  methods: {
    backToTop() {
      if (this.isMoving) return;
      const start = window.pageYOffset;
      let i = 0;
      this.isMoving = true;
      this.interval = setInterval(() => {
        const next = Math.floor(this.easeInOutQuad(10 * i, start, -start, 500));
        if (next <= 0) {
          window.scrollTo(0, 0);
          clearInterval(this.interval);
          this.isMoving = false;
        } else {
          window.scrollTo(0, next);
        }
        i++;
      }, 16.7);
    },
    easeInOutQuad(t, b, c, d) {
      if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
      return (-c / 2) * (--t * (t - 2) - 1) + b;
    },
  },
};
</script>

<style scoped lang="less">
#right-nav-page {
  position: fixed;
  top: 45%;
  right: 0px;
  width: 50px;
  height: 200px;
  -moz-opacity: 0.7;
  opacity: 0.7;
  z-index: 150;
  ul.icons {
    position: absolute;
    z-index: 180;
    list-style: none;
    right: 0px;
    padding: 0px;
    width: 50px;
    border: 1px solid #333;
    border-right: none;
    background: #111;
    -moz-border-radius: 10px 0 0 10px;
    -webkit-border-radius: 10px 0 0 10px;
    border-radius: 10px 0 0 10px;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    li {
      margin: 10px 0;
      z-index: 185;
      i {
        display: block;
        position: relative;
        width: 50px;
        height: 50px;
        // background: url(../../assets/components/icons.png) no-repeat;
        cursor: pointer;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        -ms-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        z-index: 110;
      }
      p {
        display: block;
        position: absolute;
        top: 50px;
        right: -260px;
        padding: 15px 20px;
        height: auto;
        color: White;
        line-height: 30px;
        border: 1px solid #333;
        border-right: none;
        background: #111;
        -moz-border-radius: 10px 0 0 10px;
        -webkit-border-radius: 10px 0 0 10px;
        border-radius: 10px 0 0 10px;
        -moz-opacity: 0;
        opacity: 0;
        filter: alpha(opacity=0);
        -webkit-transition: all 0.4s ease-in-out 0.1s;
        -moz-transition: all 0.4s ease-in-out 0.1s;
        -ms-transition: all 0.4s ease-in-out 0.1s;
        -o-transition: all 0.4s ease-in-out 0.1s;
        transition: all 0.4s ease-in-out 0.1s;
        overflow: hidden;
        z-index: 100;
      }
    }
    li.tel {
      p {
        width: 200px;
        margin-top: -40px;
        font-family: inherit;
        span {
          font-size: 25px;
        }
      }
      i {
        background-position: -62px -108px;
      }
      &:hover p {
        z-index: 100;
        right: 50px !important;
        opacity: 1;
      }
    }
    li.wechat {
      p {
        width: 200px;
        img {
          width: 115px;
          border: 2px solid White;
        }
      }
      i {
        background-position: -59px -66px;
      }
      &:hover p {
        z-index: 140;
        right: 50px;
        opacity: 1;
      }
    }
    li.up {
      z-index: 500;
      i {
        background-position: -63px -150px;
      }
    }
  }

  a.switch {
    position: absolute;
    display: block;
    top: 270px;
    right: 11px;
    width: 26px;
    height: 26px;
    // background: url(../../assets/components/icons.png) no-repeat 0px -640px;
    cursor: pointer;
    -webkit-transition-delay: 0.15s;
    -moz-transition-delay: 0.15s;
    -ms-transition-delay: 0.15s;
    -o-transition-delay: 0.15s;
    transition-delay: 0.15s;
  }
  a.switch.off {
    background-position: -32px -640px;
  }
  .close {
    z-index: 50;
  }
  &.close ul.icons {
    right: -70px;
  }
}
</style>
