import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    redirect: "/Home",
  },
  //首页
  {
    path: "/Home",
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },
  //产品中心
  {
    path: "/VeiBan",
    name: "VeiBan",
    // component: () => import("@/views/product/VeiBan.vue"),
    component: () => import("@/views/product/VeiBanNew.vue"),
  },
  {
    path: "/smartPark",
    name: "smartPark", 
    component: () => import("@/views/product/smartPark.vue"),
  },
  {
    path: "/smartOffice",
    name: "smartOffice", 
    component: () => import("@/views/product/smartOffice.vue"),
  },
  {
    path: "/smartMade",
    name: "smartMade", 
    component: () => import("@/views/product/smartMade.vue"),
  },
  {
    path: "/Case",
    name: "Case",
    component: () => import("@/views/case/CaseNew.vue"),
  },
  {
    path: "/News",
    name: "News",
    component: () => import("@/views/news/news.vue"),
 
  },
  {
    path: "/detail",
    name: "detail",
    component: () => import("@/views/news/details.vue"),
  },

  {
    path: "/AboutUs",
    name: "AboutUs",
    component: () => import("@/views/help/AboutUsNew.vue"),
  },
  {
    path: "/Company",
    name: "Company",
    component: () => import("@/views/help/people.vue"),
  },
  {
    path: "/Contact",
    name: "Contact",
    component: () => import("@/views/help/contactUsNew.vue"),
  },
 
  {
    path: "/parkSolution",
    name: "parkSolution",
    component: () => import("@/views/solve/park.vue"),
  },
  {
    path: "/officeSolution",
    name: "officeSolution",
    component: () => import("@/views/solve/office.vue"),
  },
  {
    path: "/madeSolution",
    name: "madeSolution",
    component: () => import("@/views/solve/made.vue"),
  },
];

const VueRouterPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(to) { 
  console.log(to)
  return VueRouterPush.call(this, to).catch((err) => err);
};

const router = new VueRouter({
  mode:'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.documentElement.scrollTop = 0;
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  next();
});

export default router;
